import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {useMatches, useNavigate, useRouteError} from "react-router-dom"

function UserOverviewError() {
  const navigate = useNavigate()
  const matches = useMatches()
  const error: any = useRouteError()
  const [key, setKey] = useState('UnfortunatelySomethingWentWrong')
  const { t } = useTranslation()

  useEffect(() => {
    if (error === 'No current user') {
      navigate(`/login?redirect=${matches[matches.length - 1].pathname}`)
    }

    if (error.data === 'Invalid customer ID') {
      setKey('RouteParameterCustomerIdIsMissingOrInvalid')
    }
  }, [error, matches, navigate, setKey])

  return (
    <Alert data-test="container.useroverview.error" severity="error">
      <AlertTitle data-test="container.useroverview.error.alert.title">{t(key)}</AlertTitle>
    </Alert>
  )
}

export default UserOverviewError
