import {useEffect, useState} from "react";
import {Auth} from "aws-amplify";

const useCurrentUser  = () => {
    const [user, setUser] = useState<any | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    const fetchUser = async() => {
        try {
            const fetchedUser = await Auth.currentAuthenticatedUser();
            setUser(fetchedUser);
        } catch (e) {
            console.error("fetch user error", e);
            setUser(null);
        }
        return true
    };

    useEffect(() => {
        fetchUser()
            .finally(() =>{
                setIsLoading(false)
            });
    }, []);

    return {user, isLoading}
}

export default useCurrentUser;