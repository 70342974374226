import {createBrowserRouter, json, Outlet} from 'react-router-dom'
import {readCustomerConfiguration, writeCustomerConfiguration} from '../api/customer_config'
import {readCustomerTree} from '../api/customer_tree'
import {readUserAssignment} from '../api/user_assignment'
import {readUserList} from '../api/user_list'
import Login from '../pages/Login/Login'
import NoMatch from '../pages/NoMatch/NoMatch'
import UserDetail from '../pages/UserDetail/UserDetail'
import UserOverview from '../pages/UserOverview/UserOverview'
import {Config} from '../types'
import {Auth} from "aws-amplify";
import Home from "../pages/Home";
import React from "react";
import RequireAuth from "../pages/RequireAuth";
import UserOverviewError from "../pages/UserOverview/UserOverviewError";

async function isAuthenticated() {
    try {
        await Auth.currentAuthenticatedUser()
        return true
    } catch (e) {
        return false
    }
}

const routes = createBrowserRouter([
    {
        id: 'Home',
        path: '/',
        element: <RequireAuth><Home/></RequireAuth>,
    },
    {
        id: 'Login',
        path: '/login',
        element: <Login/>
    },
    {
        id: 'Logout',
        path: '/logout',
        loader: async () => {
            await Auth.signOut();

            return null;
        }
    },
    {
        id: 'UserOverview',
        path: '/users/:customer_id',
        element: <RequireAuth><Outlet/></RequireAuth>,
        errorElement: <UserOverviewError/>,
        loader: async ({params}) => {
            if (!(await isAuthenticated())) {
                return null;
            }
            const config = await readCustomerConfiguration(params.customer_id!)
            if (!config) {
                throw new Response('Invalid customer ID', {status: 400})
            }
            // Set info and default values of no config entry exists
            if (config.is_enabled === null && config.require_manager_confirmation === null && config.require_onboarding === null) {
                config.is_enabled = true
                config.require_manager_confirmation = false
                config.require_onboarding = false
                config.info = 'TheConfigurationSetUpForThisCustomerIsTheDefaultConfigurationForAllCustomers'
            }

            // Set info if config entry matches the default configuration
            if (config.is_enabled === true && config.require_manager_confirmation === false && config.require_onboarding === false) {
                config.info = 'TheConfigurationSetUpForThisCustomerIsTheDefaultConfigurationForAllCustomers'
            }
            return json(config, {status: 200})
        },
        action: async ({request}) => {
            const config = (await request.formData()).get('config')! as string
            await writeCustomerConfiguration(JSON.parse(config) as Config)

            return json('', {status: 200})
        },
        children: [
            {
                id: 'UserList',
                index: true,
                element: <UserOverview/>,
                loader: async ({params}) => {
                    if (!(await isAuthenticated())) {
                        return null;
                    }
                    return await readUserList(params.customer_id!)
                }
            },
            {
                id: 'UserDetails',
                path: 'details/:user_id',
                element: <UserDetail/>,
                loader: async ({params}) => {
                    if (!(await isAuthenticated())) {
                        return null;
                    }
                    const data = {
                        customer_tree: await readCustomerTree(params.customer_id!),
                        user_assignments: await readUserAssignment(params.user_id!)
                    }

                    return json(data, {status: 200})
                }
            }
        ]
    },
    {
        id: 'NoMatch',
        path: '*',
        element: <NoMatch/>
    }
])

export default routes
