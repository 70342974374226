import {getJwtToken} from './amplify'

async function get(url: string) {
  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: await getJwtToken()
    }
  })

  if (!response.ok) {
    return null
  }

  return await response.json()
}

async function post(url: string, data: any) {
  await fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Authorization: await getJwtToken()
    },
    body: JSON.stringify(data)
  })
}

const http = {
  get,
  post
}

export default http
