import {api} from '../utils/environment'
import {Config} from '../types'
import http from '../utils/http'

export async function readCustomerConfiguration(customerId: string) {
    const url = `${api}/customer_configuration?customer_id=${customerId}`
    return (await http.get(url))[0]
}

export async function writeCustomerConfiguration(config: Config) {
    const url = `${api}/customer_configuration`
    await http.post(url, {
        customer_id: config.customer_id,
        is_enabled: config.is_enabled ?? true,
        require_manager_confirmation: config.require_manager_confirmation ?? false,
        require_onboarding: config.require_onboarding ?? false,
        show_last_wash: config.show_last_wash ?? false,
        show_last_repair: config.show_last_repair ?? true
    })
}
