// IE11 polyfills
import '@ungap/global-this';
import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/features/array/includes';
import 'core-js/stable';
import 'intl-pluralrules'
import 'proxy-polyfill/proxy.min.js';
import 'raf/polyfill';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'regenerator-runtime/runtime';

import 'promise-polyfill/src/polyfill';
import 'unfetch/polyfill';
import 'abortcontroller-polyfill';

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App'
import './i18n';
import './index.css';
import reportWebVitals from './reportWebVitals';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
