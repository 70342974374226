import React from "react";
import routes from "./routes/root";
import {RouterProvider} from "react-router-dom";
import useFederatedSignIn from "./hooks/useFederatedSignIn";

function App() {
    useFederatedSignIn()

    return (
        <RouterProvider router={routes}/>
    );
}

export default App;
