import {api} from '../utils/environment'
import http from '../utils/http'

export async function readUserAssignment(user_id: string) {
  const url = `${api}/user_assignment?user_id=${user_id}`
  return await http.get(url)
}

export async function writeUserAssignment(user_id: string, tree_ids: string[]) {
  const url = `${api}/user_assignment`
  await http.post(url, {
    user_id,
    tree_ids
  })
}
