import React from "react";

export function InfoPanel() {
    // We don't need translation here because we show german and english version at the same time.
    return (
        <div style={{display: 'flex', flexDirection: 'row', marginTop: '10vh', marginLeft: '10vw', marginRight: '10vw'}}>
            <div style={{width: '50%', marginRight: '5vw'}}>
                <div style={{marginTop: '8px', marginBottom: '8px'}}>
                    DE
                </div>
                <div style={{fontWeight: 'bold', marginBottom: '4px'}}>
                    Bitte klicken Sie auf den Button „Login with Mewa Active Directory“, um zum
                    MewaMe-Adminportal zu gelangen.
                </div>
                <div>
                    - Im Folgenden nutzen Sie bitte Ihre Mewa-E-Mail-Adresse und Ihr Windows-Passwort, um sich
                    anzumelden.
                </div>
                <div>
                    - Sollte Ihre letzte Anmeldung hier bereits länger zurückliegen, werden Sie aufgefordert,
                    einen per Mail oder SMS übermittelten Bestätigungscode einzugeben.
                </div>
                <div>
                    - Weitere Informationen erhalten Sie über die MewaMe-Service-E-Mail-Adresse
                    feedback-mewame@mewa.de
                </div>
            </div>
            <div style={{width: '50%'}}>
                <div style={{marginTop: '8px', marginBottom: '8px'}}>
                    ENG
                </div>
                <div style={{fontWeight: 'bold', marginBottom: '4px'}}>
                    Please click on the button "Login with Mewa Active Directory" button to access the MewaMe admin
                    portal.
                </div>
                <div>
                    - In the following, please use your Mewa e-mail address and your Windows password to log in.
                </div>
                <div>
                    - If it has been a while since you last logged in here, you will be asked to enter a confirmation
                    code sent to you by e-mail or SMS.
                </div>
                <div>
                    - For further information, please contact the MewaMe service e-mail address feedback-mewame@mewa.de
                </div>
            </div>
        </div>
    )
}