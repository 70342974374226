import {Amplify, Auth} from 'aws-amplify';
import * as env from "./environment";

Amplify.configure({
    Auth: {
        region: env.region,
        userPoolId: env.user_pool,
        userPoolWebClientId: env.user_pool_client,
        mandatorySignIn: true,

    },
    oauth: {
        domain: env.oauth_domain,
        scope: [
            'email',
            'profile',
            'openid',
            'aws.cognito.signin.user.admin'
        ],
        redirectSignIn: env.oauth_redirect_sign_in_url,
        redirectSignOut: env.oauth_redirect_sign_out_url,
        clientId: env.oauth_client_id,
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
})

export async function getJwtToken() {
    return new Promise<string>(function (resolve, reject) {
        Auth.currentSession()
            .then(session => resolve(session.getIdToken().getJwtToken()))
            .catch(error => {
                console.error("not able to get current session", error)
                reject("no current user session")
            })
    });
}
