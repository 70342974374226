import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';

import {Auth} from 'aws-amplify'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate, useSearchParams} from 'react-router-dom'

import {InfoPanel} from './InfoPanel';

function Login() {
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const [showLoginForm, setShowLoginForm] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const {t} = useTranslation();

    useEffect(() => {
        if (params.get('form') !== 'true') {
            setShowLoginForm(false);
        }
        else {
            setShowLoginForm(true);
        }
    }, []);

    async function login() {
        await Auth.signIn(email, password);

        if (params.get('redirect') === null) {
            navigate('/');
        }
        else {
            navigate(`${params.get('redirect')}`);
        }
    }

    async function loginAAD() {
        let redirectUrl = "";
        const redirectUrlParam = params.get('redirect')
        if (redirectUrlParam != null)
            redirectUrl = redirectUrlParam

        await Auth.federatedSignIn({customProvider: "MEWA-AAD", customState: redirectUrl});
    }

    return (
        <React.Fragment>
            {!showLoginForm ? <InfoPanel /> : null}
            <div data-test="container.login" style={{display: 'flex', justifyContent: 'center', marginTop: '5vh'}}>
                <div data-test="container.login.content.wrapper" style={{display: 'flex', flexDirection: 'column', columnGap: 4, width: 400}}>
                    {showLoginForm ? (
                        <React.Fragment>
                            <FormControl data-test="container.login.email.form.control" required={true} style={{marginBottom: 24}}>
                                <FormLabel data-test="container.login.email.form.label">{t('E-Mail')}</FormLabel>
                                <TextField data-test="container.login.email.form.input" type='email' required={true} value={email} onChange={(event) => setEmail(event.target.value)}/>
                            </FormControl>
                            <FormControl data-test="container.login.password.form.control" required={true} style={{marginBottom: 24}}>
                                <FormLabel data-test="container.login.password.form.label">{t('Password')} </FormLabel>
                                <TextField data-test="container.login.password.form.input" type='password' required={true} value={password} onChange={(event) => setPassword(event.target.value)}/>
                            </FormControl>
                            <Button data-test="container.login.submit.button" style={{width: 160, alignSelf: 'center', margin: 24}} variant="contained" onClick={login}>{t('Login')}</Button>
                            <Divider />
                        </React.Fragment>
                    ) : null}
                    <Button data-test="container.login.submit.button" style={{width: 380, alignSelf: 'center', margin: 24}} variant="contained" onClick={loginAAD}>Login with MEWA Active Directory</Button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Login
