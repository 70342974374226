export type Config = {
  customer_id: string
  customer_name: string
  is_enabled: boolean
  require_manager_confirmation: boolean
  require_onboarding: boolean
  show_last_wash: boolean
  show_last_repair: boolean
  info: string
}

export type NodeMap = {
  [key: string]:  {
    label: string
    value: string
    children?: NodeMap
  }
}

export enum Roles {
  MANAGER = 'Manager',
  WEARER = 'Wearer'
}

export type TreeNode = {
  entity: string
  entity_id: string
  name: string
  path: string
  tree_id: string
}

export type User = {
  userId: string
  wearerId: string
  role: string
  email: string
}

export type UserAssignment = {
  user_id: string
  tree_id: string
}

export type UserDetailData = {
  customer_tree: TreeNode[],
  user_assignments: UserAssignment[]
}
