import {NodeMap, TreeNode} from "../../types";

export const buildNodeMapFromTreeNodes = (tree: TreeNode[]) => {
    const tree_ids: string[] = []
    const node_map: NodeMap = {}

    function createNode(path: string[], index: number, treeItem: any, nodeMapChild: any): any {
        if (!path[index + 1]) {
            nodeMapChild[treeItem.tree_id] = {
                label: `${treeItem.name} [ ${treeItem.entity_id} ]`,
                value: treeItem.tree_id,
                children: {}
            }
            return;
        }
        if (nodeMapChild[path[index]]) {
            createNode(path, index + 1, treeItem, nodeMapChild[path[index]].children);
        }
    }

    for (const tree_item of tree) {
        const path = tree_item.path.split('|')

        createNode(path, 0, tree_item, node_map);
    }

    return {nodeMap: convert_to_array(node_map), treeIds: tree_ids};
}

export const convert_to_array = (node_map: NodeMap) => {
    const values: any = Object.values(node_map)

    for (const value of values) {
        if (value.children) {
            value.children = convert_to_array(value.children)
        }
    }

    return values
}