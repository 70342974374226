import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import {useMemo, useState} from "react"
import CheckboxTree, {Node} from 'react-checkbox-tree'
import {useLoaderData, useNavigate, useParams, useRouteLoaderData} from "react-router-dom"
import {writeUserAssignment} from "../../api/user_assignment"
import {Config, TreeNode, UserDetailData} from "../../types"

import '@fortawesome/fontawesome-free/css/v4-font-face.css'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'
import {buildNodeMapFromTreeNodes} from "../../utils/User/user-detail"
import {useTranslation} from "react-i18next"

function UserDetail() {
  const data = useLoaderData() as UserDetailData
  const navigate = useNavigate()
  const {t} = useTranslation();
  const {user_id} = useParams()
  const config = useRouteLoaderData('UserOverview') as Config
  const [checked, setChecked] = useState<string[]>(data.user_assignments.map((value) => value.tree_id))
  const [expanded, setExpanded] = useState<string[]>([])

  const nodes = useMemo(() => {
    const tree: TreeNode[] = data.customer_tree
    const {nodeMap, treeIds} = buildNodeMapFromTreeNodes(tree)
    setExpanded(treeIds)
    return nodeMap;
  }, [data]) as Node[]

  function persistUserAssignment() {
    writeUserAssignment(user_id!, checked)
  }

  return (
    <Box data-test="container.userdetails" m={20}>
      <div data-test="container.userdetails.userid.label" style={{fontSize: 32, fontWeight: 'bold'}}>{user_id}</div>
      <div data-test="container.userdetails.customername.label" style={{fontSize: 24, fontWeight: 'bold'}}>{config.customer_name}</div>
      <div data-test="container.userdetails.customerid.label" style={{fontWeight: 'bold', marginBottom: 32}}>[ {config.customer_id} ]</div>
      <CheckboxTree
        data-test="container.userdetails.user.checkboxtree"
        nodes={nodes}
        checked={checked}
        showExpandAll={true}
        expanded={expanded}
        onCheck={checked => setChecked(checked)}
        onExpand={expanded => setExpanded(expanded)}
        lang={{
          collapseAll: t("user_node_tree_collapse_all"),
          expandAll: t("user_node_tree_expand_all"),
          toggle: t("user_node_tree_toggle")
        }}
      />
      <Button data-test="container.userdetails.cancel.button" style={{marginTop: 32, marginRight: 16}} variant="outlined" onClick={() => navigate(`/users/${config.customer_id}`)}>{t("global_cancel")}</Button>
      <Button data-test="container.userdetails.save.button" style={{marginTop: 32}} variant="contained" onClick={persistUserAssignment}>{t("global_save")}</Button>
    </Box>
  )
}

export default UserDetail
