import {api} from '../utils/environment'
import http from '../utils/http'

export async function writeUserRole(user_id: string, role: string) {
  const url = `${api}/user_role`
  await http.post(url, {
    user_id,
    role
  })
}
