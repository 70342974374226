import {Navigate, useLocation, useMatches} from "react-router-dom";
import useCurrentUser from "../hooks/useCurrentUser";

function RequireAuth({children}: { children: JSX.Element }) {
    const {user, isLoading} = useCurrentUser()
    const matches = useMatches()
    const redirectUrlParam = `redirect=${matches[matches.length - 1].pathname}`
    const loginUrl = `/login?${redirectUrlParam}`
    const location = useLocation();
    if (isLoading)
        return <></>
    return user ? children : <Navigate to={loginUrl} state={{from: location}} replace/>;
}

export default RequireAuth;