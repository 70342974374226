import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import {useTranslation} from "react-i18next"

function NoMatch() {
  const { t } = useTranslation()

  return (
    <Alert severity="error">
      <AlertTitle>{t('UrlDidNotMatchAnyRoute')}</AlertTitle>
    </Alert>
  )
}

export default NoMatch
