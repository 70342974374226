import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import * as en from "./languages/en.json"
import * as de from "./languages/de.json"

i18n.use(initReactI18next).init({
  resources: {
    en,
    de
  },
  lng: 'de',
  interpolation: {
    escapeValue: false
  },
  fallbackLng: 'de'
})

//sets page language to browser's language
i18n.changeLanguage(navigator.language)

export default i18n
