import LaunchIcon from '@mui/icons-material/Launch';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useLoaderData, useNavigate, useRouteLoaderData, useSubmit} from 'react-router-dom'
import {writeUserRole} from '../../api/user_role'
import {Config, Roles, User} from '../../types'
import {TableHead} from "@mui/material";

function sortUsers(a: User, b: User) {
    if (a.userId === b.userId) {
        return 0;
    }

    if (a.userId === null) {
        return 1;
    }

    if (b.userId === null) {
        return -1;
    }

    if (a.role === b.role) {
        return a.userId < b.userId ? -1 : 1
    }

    return a.role === Roles.MANAGER ? -1 : 1
}

function UserOverview() {
    const users = useLoaderData() as User[]
    const config = useRouteLoaderData('UserOverview') as Config
    const navigate = useNavigate()
    const [, setTime] = useState(Date.now())
    const [showUnregistered, setShowUnregistered] = useState(true);
    const submit = useSubmit()
    const {t} = useTranslation()

    const tableRows = []

    const filterUsersFn = (aUser: User) => {
        return !(!showUnregistered && aUser.userId === null);
    }

    const filteredUsers = users.filter(filterUsersFn);

    let index = 0;

    for (const user of filteredUsers.sort(sortUsers)) {
        index++;
        tableRows.push(
            <TableRow data-test="container.useroverview.table.body.row" key={user.wearerId + index}>
                <TableCell data-test="container.useroverview.table.body.column.userid">{user.userId}</TableCell>
                <TableCell data-test="container.useroverview.table.body.column.wearerid">{user.wearerId}</TableCell>
                <TableCell data-test="container.useroverview.table.body.column.email">{user.email}</TableCell>
                <TableCell
                    data-test="container.useroverview.table.body.column.role">{t(user.role ?? Roles.WEARER)}</TableCell>
                <TableCell data-test="container.useroverview.table.body.column.togglerole">
                    {user.userId !== null ? (<Switch defaultChecked={user.role === Roles.MANAGER}
                                                     onChange={() => toggleRole(user)}/>) : t('not_registered_info')}
                </TableCell>
                <TableCell data-test="container.useroverview.table.body.column">
                    {user.role === Roles.MANAGER && user.userId !== null &&
                        <IconButton data-test="container.useroverview.component.icon.button" color="primary"
                                    aria-labelledby="Edit User" onClick={() => navigate(`details/${user.userId}`)}>
                            <LaunchIcon/>
                        </IconButton>
                    }
                </TableCell>
            </TableRow>
        )
    }

    function toggleConfigParam(param: 'is_enabled' | 'require_manager_confirmation' | 'require_onboarding' | 'show_last_wash' | 'show_last_repair') {
        config[param] = !config[param]
        const data = new FormData()
        data.append('config', JSON.stringify(config))
        submit(data, {method: 'post', action: `/users/${config.customer_id}`})
    }

    function toggleRole(user: User) {
        user.role = !user.role || user.role === Roles.WEARER ? Roles.MANAGER : Roles.WEARER
        writeUserRole(user.userId, user.role)
        setTime(Date.now())
    }

    function toggleAllowUseOfApp() {
        // Turn also off 'allow anonymous repairs' when 'allow use of mme' is turned off
        if (config['is_enabled'] && !config['require_onboarding']) {
            config['require_onboarding'] = !config['require_onboarding'];
        }

        toggleConfigParam('is_enabled');
    }

    return (
        <Box data-test="container.useroverview" m={20}>
            <div data-test="container.useroverview.customername.label"
                 style={{fontSize: 32, fontWeight: 'bold'}}>{config.customer_name}</div>
            <div data-test="container.useroverview.customerid.label"
                 style={{fontWeight: 'bold'}}>[ {config.customer_id} ]
            </div>
            {config.info && <Alert data-test="container.useroverview.alert.container" severity="info">
                <AlertTitle data-test="container.useroverview.alert.title">{t(config.info)}</AlertTitle>
            </Alert>}
            <div style={{display: 'flex', justifyContent: 'space-between', padding: '40px 10px'}}>
                {/* First Box */}
                <div style={boxStyle}>
                    <div style={headerStyle}>Mewa-Me-Basiskonfiguration</div>
                    <div style={contentStyle}>
                        <FormControl data-test="container.useroverview.switch.wrapper"
                                     style={{display: 'flex', flexDirection: 'row', marginTop: 32}}>
                            <Switch data-test="container.useroverview.enabled.toggle" checked={config.is_enabled}
                                    onChange={() => toggleAllowUseOfApp()}/>
                            <FormLabel data-test="container.useroverview.enabled.label"
                                       style={{lineHeight: 2}}>{t('AllowUseOfMewaMeApplication')}</FormLabel>
                        </FormControl>
                        <FormControl data-test="container.useroverview.switch.wrapper"
                                     style={{display: 'flex', flexDirection: 'row', marginTop: 16}}>
                            <Switch data-test="container.useroverview.managerconfirmation.toggle"
                                    checked={!config.require_manager_confirmation}
                                    onChange={() => toggleConfigParam('require_manager_confirmation')}/>
                            <FormLabel data-test="container.useroverview.managerconfirmation.label"
                                       style={{lineHeight: 2}}>{t('AllowOnboardingWithoutManagerConfirmation')}</FormLabel>
                        </FormControl>
                        <FormControl data-test="container.useroverview.switch.wrapper"
                                     style={{display: 'flex', flexDirection: 'row', marginTop: 16}}>
                            <Switch data-test="container.useroverview.requireonboarding.toggle"
                                    checked={!config.require_onboarding}
                                    onChange={() => toggleConfigParam('require_onboarding')}/>
                            <FormLabel data-test="container.useroverview.requireonboarding.label"
                                       style={{lineHeight: 2}}>{t('AllowRepairRequestsWithoutBeingLoggedIn')}</FormLabel>
                        </FormControl>
                        <FormControl data-test="container.useroverview.switch.wrapper"
                                     style={{display: 'flex', flexDirection: 'row', marginTop: 16, marginBottom: 32}}>
                            <Switch data-test="container.useroverview.showunregistered.toggle"
                                    checked={showUnregistered}
                                    onChange={() => setShowUnregistered(!showUnregistered)}/>
                            <FormLabel data-test="container.useroverview.showunregistered.label"
                                       style={{lineHeight: 2}}>{t('ToggleUnregisteredUsers')}</FormLabel>
                        </FormControl>
                    </div>
                </div>

                {/* Second Box */}
                <div style={boxStyle}>
                    <div style={headerStyle}>{t('content_and_functions')}</div>
                    <div style={contentStyle}>
                        <div style={{padding: '5px 0px 0px 15px', fontWeight: 'bold'}}>
                            <div>{t('Content')}</div>
                        </div>
                        <FormControl data-test="container.useroverview.switch.wrapper"
                                     style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
                            <Switch data-test="container.useroverview.last_wash.toggle" checked={config.show_last_wash}
                                    onChange={() => toggleConfigParam('show_last_wash')}/>
                            <FormLabel data-test="container.useroverview.last_wash.label"
                                       style={{lineHeight: 2}}>{t('display_last_wash')}</FormLabel>
                        </FormControl>
                        <FormControl data-test="container.useroverview.switch.wrapper"
                                     style={{display: 'flex', flexDirection: 'row', marginTop: 16}}>
                            <Switch data-test="container.useroverview.last_repair.toggle"
                                    checked={config.show_last_repair}
                                    onChange={() => toggleConfigParam('show_last_repair')}/>
                            <FormLabel data-test="container.useroverview.last_repair.label"
                                       style={{lineHeight: 2}}>{t('display_last_repair')}</FormLabel>
                        </FormControl>
                    </div>
                </div>
            </div>
            <TableContainer data-test="container.useroverview">
                <Table data-test="container.useroverview.table">
                    <TableHead>
                        <TableRow data-test="container.useroverview.table.row">
                            <TableCell data-test="container.useroverview.table.column.userid">{t('UserID')}</TableCell>
                            <TableCell
                                data-test="container.useroverview.table.column.wearerid">{t('WearerID')}</TableCell>
                            <TableCell data-test="container.useroverview.table.column.email">{t('E-Mail')}</TableCell>
                            <TableCell data-test="container.useroverview.table.column.role">{t('Role')}</TableCell>
                            <TableCell data-test="container.useroverview.table.column.empty"></TableCell>
                            <TableCell
                                data-test="container.useroverview.table.column.editlocation">{t('EditLocationsForManager')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody data-test="container.useroverview.table.body">
                        {tableRows}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

const boxStyle: React.CSSProperties = {
    flex: 1,
    borderRadius: 8,
    margin: '0 5px',
    border: '1px solid black'
};

const headerStyle: React.CSSProperties = {
    backgroundColor: '#ccc',
    padding: '5px',
    borderRadius: '8px 8px 0 0',
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
};

const contentStyle: React.CSSProperties = {
    marginTop: '10px',
};

export default UserOverview
