import Box from '@mui/material/Box';

import useCurrentUser from "../hooks/useCurrentUser";

function Home() {
    const {user} = useCurrentUser();


    return (
        <Box m={20}>
            <div style={{fontSize: 32}}>Hello <b>{user?.attributes?.email}</b></div>
        </Box>
    );
}

export default Home;
